import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MultiselectAutocomplete, { Option } from 'components/MultiselectAutocomplete';
import { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { getConfig, saveFilterConfig } from 'api/config';
import { ContactCreationSettingValue, FilterOptions } from 'api/types';
import { CodeContext } from 'context/CodeContext';
import { AuthContext } from 'context/AuthContext';
import ApiResponseMessage from 'components/ApiResponseMessage';

export default function FilterConfigPage() {
  const authContextProperties = useContext(AuthContext);
  const codes = useContext(CodeContext);
  const [industryOptions, setIndustryOptions] = useState<Option[]>([]);
  const [roleOptions, setRoleOptions] = useState<Option[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<Option[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Option[]>([]);
  const [selectedContactCreationSetting, setSelectedContactCreationSetting] = useState<ContactCreationSettingValue>(
    ContactCreationSettingValue.None,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [configData, setConfigData] = useState<FilterOptions | undefined>(undefined);
  const [associateContactsToCompany, setAssociateContactsToCompany] = useState<boolean>(false);
  const [skipSitesInImport, setSkipSitesInImport] = useState<boolean>(false);

  useEffect(fetchConfigData, [authContextProperties, setConfigData]);

  useEffect(
    function populateCodeOptions() {
      setIndustryOptions(
        codes?.industries?.map(({ code, codeExplanationFi }) => {
          return { label: `${code} ${codeExplanationFi}`, value: code };
        }) ?? [],
      );
      setRoleOptions(
        codes?.roles?.map(({ codeExplanationFi, code }) => {
          return { label: codeExplanationFi, value: code };
        }) ?? [],
      );
    },
    [codes],
  );

  useEffect(
    function populateConfigOptions() {
      setSelectedIndustries(industryOptions.filter(({ value }) => configData?.industryCodes?.includes(value)) ?? []);
      setSelectedRoles(roleOptions.filter(({ value }) => configData?.roleCodes?.includes(value)) ?? []);
      setAssociateContactsToCompany(configData?.associateContactsToCompany ?? false);
      setSkipSitesInImport(configData?.skipSitesInImport ?? false);
      setSelectedContactCreationSetting(configData?.contactCreationSetting ?? ContactCreationSettingValue.None);
    },
    [
      configData,
      industryOptions,
      roleOptions,
      setSelectedIndustries,
      setSelectedRoles,
      setSelectedContactCreationSetting,
    ],
  );

  function fetchConfigData() {
    getConfig(authContextProperties).then((config) => setConfigData(config));
  }
  function handleSave() {
    setIsSaving(true);
    setSuccessMessage(null);
    setSaveError(null);
    const newConfig = {
      industryCodes: selectedIndustries.map(({ value }) => value),
      roleCodes: selectedRoles.map(({ value }) => value),
      associateContactsToCompany: associateContactsToCompany,
      skipSitesInImport: skipSitesInImport,
      contactCreationSetting: selectedContactCreationSetting,
    };
    saveFilterConfig(newConfig, authContextProperties).then(handleSaveSuccess).catch(handleSaveFailure);
  }
  function handleSaveSuccess() {
    setIsSaving(false);
    setSuccessMessage('Suodattimien tallennus onnistui');
    fetchConfigData();
  }
  function handleSaveFailure(reason: string) {
    setIsSaving(false);
    setSaveError(reason.toString());
  }
  function handleIndustryChange(_: SyntheticEvent, values: Option[]) {
    setSelectedIndustries(values);
  }
  function handleRoleChange(_: SyntheticEvent, values: Option[]) {
    setSelectedRoles(values);
  }
  function handleJoinContactsToCompany(_: ChangeEvent<HTMLInputElement>, value: boolean) {
    setAssociateContactsToCompany(value);
  }
  function handleSkipSitesInImport(_: ChangeEvent<HTMLInputElement>, value: boolean) {
    setSkipSitesInImport(value);
  }
  function handleContactCreationSettingValueChange(_: React.ChangeEvent<HTMLInputElement>, value: string) {
    setSelectedContactCreationSetting(value as ContactCreationSettingValue);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ marginTop: 2 }}>
        <>
          {codes?.isLoading || (
            <Stack spacing={2}>
              <Typography variant="h6">Toimialat</Typography>
              <MultiselectAutocomplete
                selected={selectedIndustries}
                label="Hae"
                options={industryOptions}
                onChange={handleIndustryChange}
              />
              <Typography variant="h6">Vastuualueet</Typography>
              <MultiselectAutocomplete
                selected={selectedRoles}
                label="Hae"
                options={roleOptions}
                onChange={handleRoleChange}
              />
              <FormControl>
                <Typography variant="h6">Päättäjäasetukset</Typography>
                <FormControlLabel
                  control={<Checkbox checked={associateContactsToCompany} onChange={handleJoinContactsToCompany} />}
                  label="Liitä päättäjät myös toimipaikan yritykseen"
                />
                <FormControlLabel
                  control={<Checkbox checked={skipSitesInImport} onChange={handleSkipSitesInImport} />}
                  label="Älä tuo toimipaikkoja (päättäjät yhdistetään suoraan yritykseen)"
                />
              </FormControl>
              <FormControl>
                <Typography variant="h6">Uusien kontaktien kohdistus</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedContactCreationSetting}
                  name="radio-buttons-group"
                  onChange={handleContactCreationSettingValueChange}
                >
                  <FormControlLabel
                    value={ContactCreationSettingValue.None}
                    control={<Radio />}
                    label="Älä kohdista automaattisesti"
                  />
                  <FormControlLabel
                    value={ContactCreationSettingValue.FirstIfIntegrated}
                    control={<Radio />}
                    label="Kohdista kontakti, mikäli yritys on jo Tietohuollossa"
                  />
                  <FormControlLabel
                    value={ContactCreationSettingValue.FirstIfNotIntegrated}
                    control={<Radio />}
                    label="Kohdista kontakti ja tuo yritys automaattisesti, mikäli yritys ei ole Tietohuollossa"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          )}
          {!codes?.isLoading || <CircularProgress />}
          <ApiResponseMessage errorMessage={saveError} successMessage={successMessage} />
        </>
      </Box>
      <Box sx={{ alignSelf: 'flex-end', marginTop: 'auto', marginBottom: 2 }}>
        <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton variant="contained" onClick={handleSave} loading={isSaving}>
            Tallenna
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
}
