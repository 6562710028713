import { FilterOptions, ApiTokenOptions, ImportCompaniesOptions, Request } from 'api/types';
import { AsyncData, jsonFetcher, prepareAsyncData, jsonPoster } from 'api/util';
import appConfig from 'appConfig';
import { AuthContextProperties } from 'context/AuthContext';
import useSWR from 'swr';

export function useConfig({
  signature,
  timestamp,
  app,
  appId,
  userId,
}: AuthContextProperties): AsyncData<FilterOptions> {
  signature = encodeURIComponent(signature);
  const url = `${appConfig.backendUrl}/customer-meta/configuration/filters?sig=${signature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
  const response = useSWR(signature && timestamp && app && appId ? url : null, jsonFetcher);
  return prepareAsyncData(response);
}

export function getConfig({ signature, timestamp, app, appId, userId }: AuthContextProperties): Promise<FilterOptions> {
  signature = encodeURIComponent(signature);
  const url = `${appConfig.backendUrl}/customer-meta/configuration/filters?sig=${signature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
  return jsonFetcher<FilterOptions>(url);
}

export function saveFilterConfig(filterOptions: FilterOptions, auth: AuthContextProperties): Promise<FilterOptions> {
  return saveConfig('filters', filterOptions, auth);
}

export function saveApiTokenConfig(apiTokenOptions: ApiTokenOptions, auth: AuthContextProperties) {
  return saveConfig('api-token', apiTokenOptions, auth);
}

export function importCompanies(companiesOptions: ImportCompaniesOptions, auth: AuthContextProperties) {
  return saveConfig('company-import', companiesOptions, auth);
}

function saveConfig<TConfig>(path: string, config: TConfig, auth: AuthContextProperties): Promise<TConfig> {
  const body: Request<TConfig> = {
    signedRequestContent: {
      signature: auth.signature,
      timestamp: parseInt(auth.timestamp),
      fields: {
        app: auth.app,
        appId: auth.appId,
        userId: auth.userId,
      },
    },
    variableRequestContent: config,
  };
  return jsonPoster<TConfig>(`${appConfig.backendUrl}/customer-meta/configuration/${path}`, body);
}
