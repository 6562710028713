import { ReactElement } from 'react';
import { Route, Routes } from 'react-router';
import ContactImportPage from 'pages/ContactImport';
import SiteImportPage from 'pages/SiteImport';
import App from 'App';
import ApiTokenConfigPage from 'pages/ApiTokenConfig';
import CompanyImportPage from 'pages/CompanyImport';
import FilterConfigPage from 'pages/FilterConfig';
import HomePage from 'pages/Home';
import CompanyStructureImportPage from 'pages/CompanyStructureImport';
import AnalyzerIntegrationPage from 'pages/AnalyzerIntegrationPage';

export default function SiteRoutes(): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<HomePage />} />
        <Route path="filter-config" element={<FilterConfigPage />} />
        <Route path="api-token-config" element={<ApiTokenConfigPage />} />
        <Route path="company-import" element={<CompanyImportPage />} />
        <Route path="contact-import" element={<ContactImportPage />} />
        <Route path="site-import" element={<SiteImportPage />} />
        <Route path="company-structure-import" element={<CompanyStructureImportPage />} />
        <Route path="analyzer-integration" element={<AnalyzerIntegrationPage />} />
        <Route path="hubspot"></Route>
      </Route>
    </Routes>
  );
}
