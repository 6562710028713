export interface PluginTableColumn {
  readonly columnName: string;
  readonly objectKey: string;
  readonly cellType?: CellType;
}

export enum CellType {
  String,
  Percentage,
}
