import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

interface Props {
  buttonsDisabled: boolean;
  loading: boolean;
  submit: () => void;
  cancel: () => void;
}

export function ImportButtons({ buttonsDisabled, loading, submit, cancel }: Props) {
  return (
    <Stack direction="row" justifyContent="flex-end" gap="8px" sx={{ padding: '16px 0px' }}>
      <Button variant="outlined" disabled={buttonsDisabled} onClick={cancel}>
        Peruuta
      </Button>
      <LoadingButton variant="contained" color="primary" disabled={buttonsDisabled} onClick={submit} loading={loading}>
        Tuo
      </LoadingButton>
    </Stack>
  );
}
