import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import ApiResponseMessage from 'components/ApiResponseMessage';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { saveApiTokenConfig } from 'api/config';
import { AuthContext } from 'context/AuthContext';

export default function ApiTokenConfigPage() {
  const [saveError, setSaveError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const authContextProps = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams] = useSearchParams();
  const [apiToken, setApiToken] = useState('');
  const code = searchParams.get('code');
  const redirectTo = searchParams.get('redirect_to');

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSaving(true);
    setSuccessMessage(null);
    setSaveError(null);
    if (redirectTo) {
      window.location.href = `${redirectTo}?code=${code}&token=${apiToken}`;
      return;
    }
    saveApiTokenConfig({ apiToken: apiToken }, authContextProps).then(handleSaveSuccess).catch(handleSaveFailure);
  }
  function handleSaveSuccess() {
    setIsSaving(false);
    setSuccessMessage('API tokenin tallennus onnistui');
    setApiToken('');
  }
  function handleSaveFailure(reason: string) {
    setIsSaving(false);
    setSaveError(reason.toString());
  }
  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setApiToken(e.target.value);
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography sx={{ margin: '20px 0' }} variant="h6">
        Lisää Tietohuollon API token
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            sx={{ minWidth: 500 }}
            label="API token"
            variant="outlined"
            value={apiToken}
            onChange={handleChange}
          />
          <LoadingButton type="submit" variant="contained" loading={isSaving}>
            Tallenna
          </LoadingButton>
        </Stack>
      </form>
      <ApiResponseMessage errorMessage={saveError} successMessage={successMessage} />
    </Box>
  );
}
