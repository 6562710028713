import { Box, CircularProgress, Typography, TextField } from '@mui/material';
import { saveCompanies, useCompanies } from 'api/company-data';
import { ApiError, Member } from 'api/types';
import {
  getSavedItems,
  getUnsavedItems,
  getUpdatedSelectedItemsForCompanies,
  getItemsToSaveByBusinessId,
} from 'apila-util';
import CompanyImportTable from 'components/CompanyStructureImportTable';
import { ImportButtons } from 'components/ImportButtons';
import { AuthContext } from 'context/AuthContext';
import { CodeContext } from 'context/CodeContext';
import { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Code } from 'types/code';

function filterCompanies(members: Member[], positions: Code[], searchTerm?: string): Member[] {
  const searchTermLowerCase = (searchTerm ?? '').toLowerCase();
  return members
    .map((member) => {
      const memberPosition = member.positionInGroup;
      const positionInGroupDisplay = positions.find((position) => position.code === memberPosition)
        ?.codeExplanationFi as string;
      return { ...member, positionInGroupDisplay };
    })
    .filter((member) => getMemberOfEnterpriseGroupAsString(member).includes(searchTermLowerCase));
}

function getMemberOfEnterpriseGroupAsString(member: Member): string {
  return `${member.name}:${member.positionInGroupDisplay}:${member.ownership}`.toLowerCase();
}

export default function CompanyStructureImportPage() {
  const authContextProps = useContext(AuthContext);
  const codeContext = useContext(CodeContext);
  const [searchParams] = useSearchParams({ crmObjectId: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const { data, isLoading, refresh } = useCompanies(authContextProps, searchParams.get('crmObjectId') ?? '');
  const [selectedItems, setSelectedItems] = useState<Member[]>([]);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(
    function populateSelectedItems() {
      setSelectedItems(getSavedItems(data.membersOfEnterpriseGroup));
    },
    [data],
  );

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
    setPage(0);
  }

  function onSelectItem(event: React.ChangeEvent<Element>, member: Member) {
    const newSelected = getUpdatedSelectedItemsForCompanies(selectedItems, member);
    setSelectedItems(newSelected);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangePage(_: unknown, newPage: number) {
    setPage(newPage);
  }

  function submit() {
    setIsSaving(true);
    const companyStructureMembersIdsToSave = getItemsToSaveByBusinessId(selectedItems);
    saveCompanies(authContextProps, searchParams.get('crmObjectId') ?? '', {
      businessIds: companyStructureMembersIdsToSave,
    })
      .then(handleSubmitSuccess)
      .catch(handleSubmitError)
      .finally(() => {
        refresh();
        setIsSaving(false);
      });
  }

  function handleSubmitError(error: ApiError) {
    if (error.errorCode) {
      setSubmitErrorMessage('Konsernin jäsenien tallentaminen ei onnistunut.');
      return;
    }
    setSubmitErrorMessage('Tallennettaessa tapahtui tuntematon virhe');
  }

  function handleSubmitSuccess(response: unknown) {
    setSubmitErrorMessage(undefined);
  }

  function cancel() {
    setSelectedItems(getSavedItems(selectedItems));
  }

  const buttonsDisabled = getUnsavedItems(selectedItems).length === 0 || isLoading || isSaving;

  const members: Member[] = filterCompanies(
    data.membersOfEnterpriseGroup,
    codeContext?.positionInGroup ?? [],
    searchTerm,
  ).slice(0);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography variant="h6">{data.enterpriseGroupName}</Typography>
          <Typography variant="body1" sx={{ padding: '8px 0px' }} color="text.secondary">
            Valitse konsernin jäsenet, jotka tuodaan Hubspotiin. Jo lisätyt yritykset näkyvät listalla harmaana.
          </Typography>
          <TextField
            variant="outlined"
            sx={{ padding: '16px 16px' }}
            placeholder="Hae konsernin jäsenyrityksiä..."
            value={searchTerm}
            onChange={onSearchChange}
          />
          {submitErrorMessage && (
            <Typography variant="body1" sx={{ padding: '8px 0px' }} color="error">
              {submitErrorMessage}
            </Typography>
          )}
          <CompanyImportTable
            rows={members}
            numSelected={selectedItems.length}
            selectedItems={selectedItems}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onSelectItem={onSelectItem}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      {members.length > 0 && (
        <ImportButtons buttonsDisabled={buttonsDisabled} submit={submit} cancel={cancel} loading={isSaving} />
      )}
    </Box>
  );
}
