import { Stack, Button } from '@mui/material';
import appConfig from 'appConfig';
import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';

export default function AnalyzerIntegrationPage() {
  const authContextProperties = useContext(AuthContext);
  const href =
    `${appConfig.analyzerUrl}/asetukset/${authContextProperties.app.toLowerCase()}` +
    `?app=${authContextProperties.app}` +
    `&appId=${authContextProperties.appId}` +
    `&userId=${authContextProperties.userId}` +
    `&timestamp=${authContextProperties.timestamp}` +
    `&sig=${authContextProperties.signature}`;
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: '100vh' }}>
      <Button href={href} target="_blank" variant="contained">
        Yhdistä Analysaattoriin
      </Button>
    </Stack>
  );
}
