import { TableHead, TableRow, TableCell } from '@mui/material';

interface Props {
  readonly columnNames: string[];
}

export function PluginTableHead({ columnNames }: Props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columnNames.map((columnName) => (
          <TableCell>{columnName}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
