import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { saveContacts, useCompanyContacts } from 'api/company-data';
import { ApiError, Contact } from 'api/types';
import { getItemsToSave, getSavedItems, getUnsavedItems, getUpdatedSelectedItems, isNil } from 'apila-util';
import ContactImportTable from 'components/ContactImportTable';
import { ImportButtons } from 'components/ImportButtons';
import { AuthContext } from 'context/AuthContext';
import { CodeContext } from 'context/CodeContext';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Code } from 'types/code';

function filterAndEnrichContacts(contacts: Contact[], roles: Code[], searchTerm?: string): Contact[] {
  const searchTermLowerCase = searchTerm?.toLowerCase() ?? '';
  return contacts
    .map((contact) => {
      const rolesDisplay = contact.roleCodes
        .map((roleCode) => roles.find((role) => role.code === roleCode)?.codeExplanationFi)
        .filter((explanation) => !isNil(explanation))
        .join(', ');
      return { ...contact, rolesDisplay };
    })
    .filter((contact) =>
      `${contact.name}:${contact.title}:${contact.rolesDisplay}`.toLowerCase().includes(searchTermLowerCase),
    );
}

function sortBySiteType(a: Contact, b: Contact): number {
  const aSiteType = a.siteType ?? '';
  const bSiteType = b.siteType ?? '';
  return aSiteType.localeCompare(bSiteType);
}

export default function ContactImportPage() {
  const [page, setPage] = useState(0);
  const authContextProps = useContext(AuthContext);
  const codeContext = useContext(CodeContext);
  const [searchParams] = useSearchParams({ crmObjectId: '' });
  const { data, isLoading, refresh } = useCompanyContacts(
    authContextProps,
    searchParams.get('crmObjectId') ?? '',
    searchParams.get('itemType') ?? '',
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItems, setSelectedItems] = useState<Contact[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>();

  useEffect(
    function populateSelectedItems() {
      setSelectedItems(getSavedItems(data.contacts));
    },
    [data],
  );

  function handleChangePage(_: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onSelectItem(_: React.ChangeEvent, contact: Contact) {
    const newSelected = getUpdatedSelectedItems(selectedItems, contact);
    setSelectedItems(newSelected);
  }

  function submit() {
    setIsSaving(true);
    const contactIdsToSave = getItemsToSave(selectedItems);
    saveContacts(authContextProps, searchParams.get('crmObjectId') ?? '', searchParams.get('itemType') ?? '', {
      almaIds: contactIdsToSave,
    })
      .then(handleSubmitSuccess)
      .catch(handleSubmitError)
      .finally(() => {
        refresh();
        setIsSaving(false);
      });
  }

  function cancel() {
    setSelectedItems(getSavedItems(selectedItems));
  }

  const contacts = filterAndEnrichContacts(data.contacts, codeContext?.roles ?? [], searchTerm)
    .slice(0)
    .sort(sortBySiteType);

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
    setPage(0);
  }

  function handleSubmitError(error: ApiError) {
    if (error.errorCode) {
      setSubmitErrorMessage(
        'Useammalla päättäjällä on sama sähköpostiosoite tai sähköpostiosoite on tallennettu jo Hubspotiin.',
      );
      return;
    }
    setSubmitErrorMessage('Tallennettaessa tapahtui tuntematon virhe');
  }

  function handleSubmitSuccess(response: unknown) {
    setSubmitErrorMessage(undefined);
  }

  const buttonsDisabled = getUnsavedItems(selectedItems).length === 0 || isLoading || isSaving;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography variant="body1" sx={{ padding: '8px 0px' }} color="text.secondary">
            Valitse päättäjät, jotka tuodaan Hubspotiin.
          </Typography>
          <TextField
            variant="outlined"
            sx={{ padding: '16px 16px' }}
            placeholder="Hae päättäjää..."
            value={searchTerm}
            onChange={onSearchChange}
          />
          {submitErrorMessage && (
            <Typography variant="body1" sx={{ padding: '8px 0px' }} color="error">
              {submitErrorMessage}
            </Typography>
          )}
          <ContactImportTable
            rows={contacts}
            numSelected={selectedItems.length}
            selectedItems={selectedItems}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onSelectItem={onSelectItem}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      {contacts.length > 0 && (
        <ImportButtons buttonsDisabled={buttonsDisabled} submit={submit} cancel={cancel} loading={isSaving} />
      )}
    </Box>
  );
}
