import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SyntheticEvent } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  readonly selected: Option[];
  readonly options: Option[];
  readonly label: string;
  readonly onChange: (event: SyntheticEvent, values: Option[]) => void;
}
export interface Option {
  readonly label: string;
  readonly value: string;
}

export default function MultiselectAutocomplete({ options, label, onChange, selected }: Props) {
  return (
    <Autocomplete
      multiple
      limitTags={5}
      value={selected}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      onChange={onChange}
      options={options}
      disableCloseOnSelect
      getOptionLabel={({ label }) => label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
