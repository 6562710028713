import { ApiError } from 'api/types';
import { SWRResponse } from 'swr';

export interface AsyncData<Data> extends SWRResponse<Data | null, Response | null> {
  readonly data: Data | null;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly error: Response | null;
}

export function prepareAsyncData(swrResponse: SWRResponse<any, Response>): AsyncData<any> {
  const { data, error, isValidating, mutate } = swrResponse;

  return {
    data: data !== undefined ? data : undefined,
    isLoading: data === undefined && error === undefined,
    isError: error !== undefined,
    isValidating,
    error: error !== undefined ? error : null,
    mutate,
  };
}

export function jsonFetcher<T>(url: string): Promise<T> {
  return fetch(url)
    .then(handleErrors)
    .then((o: Response) => o.json() as T);
}

export function jsonPoster<T>(url: string, body?: object): Promise<T> {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(handleErrors)
    .then((res) => res as T);
}

function handleErrors(response: Response | undefined): Promise<Response> {
  if (!response || !response.ok) {
    const error: ApiError = {
      errorCode: response?.status,
    };
    return Promise.reject(error);
  }
  return Promise.resolve(response);
}
