import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import { CellType, PluginTableColumn } from 'components/types';
import { isNil } from 'apila-util';

interface Props<T> {
  readonly rows: T[];
  readonly selectedItems: T[];
  readonly columns: PluginTableColumn[];
  readonly getRowId: (row: T) => string;
  readonly onSelectItem: (event: React.ChangeEvent, row: T) => void;
  readonly isRowSelected: (row: T) => boolean;
  readonly isRowDisabled: (row: T) => boolean;
}

export default function PluginTableBody<T>({
  rows,
  columns,
  getRowId,
  onSelectItem,
  isRowSelected,
  isRowDisabled,
}: Props<T>) {
  function cellValue(row: T, column: PluginTableColumn): string {
    let val = (row as { readonly [key: string]: string })[column.objectKey];
    if (!isNil(val) && column.cellType === CellType.Percentage) {
      val = `${val}${String.fromCharCode(160)}%`;
    }
    return val;
  }
  return (
    <TableBody>
      {rows.map((row) => {
        const isSelected = isRowSelected(row);
        const isDisabled = isRowDisabled(row);
        return (
          <TableRow key={getRowId(row)}>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isSelected}
                disabled={isDisabled}
                onChange={(event) => onSelectItem(event, row)}
              />
            </TableCell>
            {columns.map((column) => (
              <TableCell key={column.columnName}>{cellValue(row, column)}</TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
