import { Table, TablePagination } from '@mui/material';
import PluginTableBody from 'components/PluginTableBody';
import { PluginTableHead } from 'components/PluginTableHead';
import { PluginTableColumn } from 'components/types';
import { useMemo } from 'react';

interface Props<T> {
  readonly columns: PluginTableColumn[];
  readonly rows: T[];
  readonly selectedItems: T[];
  readonly page: number;
  readonly rowsPerPage: number;
  readonly handleChangePage: (event: unknown, pageNumber: number) => void;
  readonly handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onSelectItem: (event: React.ChangeEvent, row: T) => void;
  readonly isRowSelected: (item: T) => boolean;
  readonly isRowDisabled: (item: T) => boolean;
  readonly getRowId: (item: T) => string;
}

export function PluginTable<T>({
  columns,
  handleChangePage,
  handleChangeRowsPerPage,
  onSelectItem,
  page,
  selectedItems,
  rows,
  rowsPerPage,
  isRowDisabled,
  isRowSelected,
  getRowId,
}: Props<T>) {
  const rowCount = rows.length;
  const visibleRows: T[] = useMemo(
    function getVisibleRows() {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    },
    [page, rowsPerPage, rows],
  );
  const columnNames = columns.map((column) => column.columnName);
  return (
    <>
      <Table>
        <PluginTableHead columnNames={columnNames} />
        <PluginTableBody<T>
          columns={columns}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          getRowId={getRowId}
          rows={visibleRows}
          onSelectItem={onSelectItem}
          selectedItems={selectedItems}
        />
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
