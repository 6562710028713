import { Paper } from '@mui/material';
import { Member } from 'api/types';
import { PluginTable } from 'components/PluginTable';
import { closeIsRowSelectedByBusinessId, getRowId, isRowDisabled } from 'components/common';
import { CellType, PluginTableColumn } from 'components/types';

interface Props {
  readonly numSelected: number;
  readonly rows: Member[];
  readonly selectedItems: Member[];
  readonly page: number;
  readonly rowsPerPage: number;
  readonly handleChangePage: (event: unknown, pageNumber: number) => void;
  readonly handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onSelectItem: (event: React.ChangeEvent, row: Member) => void;
}

export default function CompanyImportTable({
  rows,
  page,
  selectedItems,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onSelectItem,
}: Props) {
  const columns: PluginTableColumn[] = [
    { columnName: 'Yritys', objectKey: 'name' },
    { columnName: 'Jäsenlaji', objectKey: 'positionInGroupDisplay' },
    { columnName: 'Konsernin omistusosuus', objectKey: 'ownership', cellType: CellType.Percentage },
  ];
  const isRowSelected = closeIsRowSelectedByBusinessId(selectedItems);
  return (
    <Paper>
      <PluginTable<Member>
        columns={columns}
        rows={rows}
        selectedItems={selectedItems}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onSelectItem={onSelectItem}
        isRowSelected={isRowSelected}
        isRowDisabled={isRowDisabled}
        getRowId={getRowId}
      />
    </Paper>
  );
}
