import { Paper } from '@mui/material';
import { Site } from 'api/types';
import { PluginTable } from 'components/PluginTable';
import { isRowDisabled, getRowId, closeIsRowSelectedByAlmaId } from 'components/common';
import { PluginTableColumn } from 'components/types';

interface Props {
  readonly numSelected: number;
  readonly rows: Site[];
  readonly selectedItems: Site[];
  readonly page: number;
  readonly rowsPerPage: number;
  readonly handleChangePage: (event: unknown, pageNumber: number) => void;
  readonly handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onSelectItem: (event: React.ChangeEvent, row: Site) => void;
}

export default function SiteImportTable({
  rows,
  page,
  selectedItems,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onSelectItem,
}: Props) {
  const columns: PluginTableColumn[] = [
    {
      columnName: 'Toimipaikan nimi',
      objectKey: 'name',
    },
    {
      columnName: 'Osoite',
      objectKey: 'address',
    },
    {
      columnName: 'Postitoimipaikka',
      objectKey: 'city',
    },
    {
      columnName: 'Päättäjien määrä',
      objectKey: 'numberOfDecisionMakers',
    },
  ];

  const isRowSelected = closeIsRowSelectedByAlmaId(selectedItems);

  return (
    <Paper>
      <PluginTable<Site>
        columns={columns}
        rows={rows}
        selectedItems={selectedItems}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onSelectItem={onSelectItem}
        isRowSelected={isRowSelected}
        isRowDisabled={isRowDisabled}
        getRowId={getRowId}
      />
    </Paper>
  );
}
