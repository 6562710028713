import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function PageLayout() {
  return (
    <Box component="main" sx={{ flexGrow: 1, minHeight: '100vh', maxWidth: '1300px' }}>
      <Outlet />
    </Box>
  );
}
