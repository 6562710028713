import { Typography } from '@mui/material';

interface Props {
  readonly errorMessage: string | null;
  readonly successMessage: string | null;
}

export default function ApiResponseMessage({ errorMessage, successMessage }: Props) {
  return (
    <>
      {errorMessage && <Typography sx={{ color: 'error.main' }}>{errorMessage}</Typography>}
      {successMessage && <Typography sx={{ color: 'success.main' }}>{successMessage}</Typography>}
    </>
  );
}
