import { ImportableAlmaObject } from 'api/types';
import { isNil } from 'apila-util';

export function closeIsRowSelectedByAlmaId(
  selectedItems: ImportableAlmaObject[],
): (item: ImportableAlmaObject) => boolean {
  return (item: ImportableAlmaObject) =>
    selectedItems.findIndex((selectedItem: ImportableAlmaObject) => selectedItem.almaId === item.almaId) !== -1;
}
export function closeIsRowSelectedByBusinessId(
  selectedItems: ImportableAlmaObject[],
): (item: ImportableAlmaObject) => boolean {
  return (item: ImportableAlmaObject) =>
    selectedItems.findIndex((selectedItem: ImportableAlmaObject) => selectedItem.businessId === item.businessId) !== -1;
}
export function isRowDisabled(almaObject: ImportableAlmaObject): boolean {
  return !isNil(almaObject.objectId);
}
export function getRowId(almaObject: ImportableAlmaObject): string {
  return almaObject.businessId;
}
