import { ImportableAlmaObject } from 'api/types';

const MAX_SELECTED_ITEMS = 10;

export function isNil<T>(value: T): boolean {
  return value === null || value === undefined;
}

export function getSavedItems<T extends ImportableAlmaObject>(items: T[]): T[] {
  return items.filter((contact) => !isNil(contact.objectId));
}

export function getUnsavedItems<T extends ImportableAlmaObject>(items: T[]): T[] {
  return items.filter((contact) => isNil(contact.objectId));
}

export function getUpdatedSelectedItems<T extends ImportableAlmaObject>(selectedItems: T[], selectCandidate: T): T[] {
  const unsavedSelectedSites = getUnsavedItems(selectedItems);
  if (unsavedSelectedSites.length >= MAX_SELECTED_ITEMS) {
    return selectedItems;
  }
  const indexOfSelectedId = selectedItems.findIndex((selectedItem) => selectCandidate.almaId === selectedItem.almaId);
  let newSelected: T[] = [];
  if (indexOfSelectedId !== -1) {
    newSelected = selectedItems.slice(0, indexOfSelectedId).concat(selectedItems.slice(indexOfSelectedId + 1));
  } else {
    newSelected = [...selectedItems, selectCandidate];
  }
  return newSelected;
}

export function getUpdatedSelectedItemsForCompanies<T extends ImportableAlmaObject>(
  selectedItems: T[],
  selectCandidate: T,
): T[] {
  const unsavedSelectedSites = getUnsavedItems(selectedItems);
  if (unsavedSelectedSites.length >= MAX_SELECTED_ITEMS) {
    return selectedItems;
  }
  const indexOfSelectedId = selectedItems.findIndex(
    (selectedItem) => selectCandidate.businessId === selectedItem.businessId,
  );
  let newSelected: T[] = [];
  if (indexOfSelectedId !== -1) {
    newSelected = selectedItems.slice(0, indexOfSelectedId).concat(selectedItems.slice(indexOfSelectedId + 1));
  } else {
    newSelected = [...selectedItems, selectCandidate];
  }
  return newSelected;
}

export function getItemsToSave<T extends ImportableAlmaObject>(selectedItems: T[]): string[] {
  return getUnsavedItems(selectedItems).map((item) => item.almaId);
}

export function getItemsToSaveByBusinessId<T extends ImportableAlmaObject>(selectedItems: T[]): string[] {
  return getUnsavedItems(selectedItems).map((item) => item.businessId);
}
