import { Box } from '@mui/material';
import { AuthContextProvider } from 'context/AuthContext';
import { CodeContextProvider } from 'context/CodeContext';
import PageLayout from 'layouts/PageLayout';

export default function App() {
  return (
    <AuthContextProvider>
      <CodeContextProvider>
        <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '96vw', margin: '0 auto' }}>
          <PageLayout />
        </Box>
      </CodeContextProvider>
    </AuthContextProvider>
  );
}
