export interface FilterOptions {
  readonly industryCodes: string[];
  readonly roleCodes: string[];
  readonly associateContactsToCompany: boolean;
  readonly skipSitesInImport: boolean;
  readonly contactCreationSetting: ContactCreationSettingValue;
}

export enum ContactCreationSettingValue {
  None = 'none',
  FirstIfIntegrated = 'firstIfIntegrated',
  FirstIfNotIntegrated = 'firstIfNotIntegrated',
}

export interface ApiTokenOptions {
  readonly apiToken: string;
}

export interface ImportCompaniesOptions {
  readonly companies: string;
}

export interface Request<TVariableRequestType> {
  readonly signedRequestContent: SignedRequestContent;
  readonly variableRequestContent: TVariableRequestType;
}

export interface SignedRequestContent {
  readonly fields: SignableRequestFields;
  readonly timestamp: number;
  readonly signature: string;
}

export interface SignableRequestFields {
  readonly appId: string;
  readonly app: string;
  readonly userId: string;
}

export interface ContactResponseBody {
  contacts: Contact[];
}

export interface ImportableAlmaObject {
  readonly almaId: string;
  readonly businessId: string;
  readonly objectId?: string;
}

export interface Contact extends ImportableAlmaObject {
  readonly name: string;
  readonly title?: string;
  readonly roleCodes: string[];
  readonly rolesDisplay: string;
  readonly siteType?: string;
}

export interface Site extends ImportableAlmaObject {
  readonly name: string;
  readonly address: string;
  readonly city: string;
  readonly numberOfDecisionMakers: number;
  readonly typeCode: string;
}

export interface Member extends ImportableAlmaObject {
  readonly name: string;
  readonly enterpriseGroupName: string;
  readonly positionInGroup: string;
  readonly positionInGroupDisplay: string;
  readonly ownership: number;
}

export interface SiteResponseBody {
  sites: Site[];
}

export interface CompanyResponseBody {
  enterpriseGroupName: string;
  membersOfEnterpriseGroup: Member[];
}

export interface IndividualImportPostBody {
  readonly almaIds: string[];
}

export interface IndividualImportInputBusinessIds {
  readonly businessIds: string[];
}

export interface ApiResponse<T> {
  readonly data: T;
  readonly isLoading: boolean;
  readonly error?: ApiError;
}

export interface RefreshableApiResponse<T> extends ApiResponse<T> {
  readonly refresh: () => void;
}

export interface ApiError {
  readonly errorCode?: number;
  readonly errorMessage?: string;
}

export const SITE_TYPE_MAIN_SITE = 'ST_1';
