import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import ApiResponseMessage from 'components/ApiResponseMessage';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { importCompanies } from 'api/config';
import { AuthContext } from 'context/AuthContext';

export default function CompanyImportPage() {
  const [saveError, setSaveError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const authContextProps = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const [companies, setCompanies] = useState('');

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSaving(true);
    setSuccessMessage(null);
    setSaveError(null);
    importCompanies({ companies }, authContextProps).then(handleSaveSuccess).catch(handleSaveFailure);
  }
  function handleSaveSuccess() {
    setIsSaving(false);
    setSuccessMessage('Yritysten lisäys tuontiin onnistui');
    setCompanies('');
  }
  function handleSaveFailure(reason: string) {
    setIsSaving(false);
    setSaveError(reason.toString());
  }
  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setCompanies(e.target.value);
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography sx={{ margin: '20px 0' }} variant="h6">
        Tuo yrityksiä
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            multiline
            sx={{ minWidth: 500 }}
            label="Lisää Y-tunnuksia"
            variant="outlined"
            value={companies}
            onChange={handleChange}
          />
          <LoadingButton type="submit" variant="contained" loading={isSaving}>
            Tallenna
          </LoadingButton>
        </Stack>
      </form>
      <ApiResponseMessage errorMessage={saveError} successMessage={successMessage} />
    </Box>
  );
}
