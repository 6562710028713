import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { saveSites, useSites } from 'api/company-data';
import { ApiError, Site } from 'api/types';
import { getItemsToSave, getSavedItems, getUnsavedItems, getUpdatedSelectedItems } from 'apila-util';
import { ImportButtons } from 'components/ImportButtons';
import SiteImportTable from 'components/SiteImportTable';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function filterSites(sites: Site[], searchTerm?: string): Site[] {
  const searchTermLowerCase = (searchTerm ?? '').toLowerCase();
  return sites.filter((site) => getSiteFieldsAsString(site).includes(searchTermLowerCase));
}

function getSiteFieldsAsString(site: Site): string {
  return `${site.address}:${site.city}:${site.name}:${site.numberOfDecisionMakers}`.toLowerCase();
}

function sortBySiteTypeCode(siteA: Site, siteB: Site): number {
  return siteA.typeCode.localeCompare(siteA.typeCode);
}

export default function SiteImportPage() {
  const authContextProps = useContext(AuthContext);
  const [searchParams] = useSearchParams({ crmObjectId: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const { data, isLoading, refresh } = useSites(authContextProps, searchParams.get('crmObjectId') ?? '');
  const [selectedItems, setSelectedItems] = useState<Site[]>([]);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(
    function populateSelectedItems() {
      setSelectedItems(getSavedItems(data.sites));
    },
    [data],
  );

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
    setPage(0);
  }

  function onSelectItem(event: React.ChangeEvent<Element>, site: Site) {
    const newSelected = getUpdatedSelectedItems(selectedItems, site);
    setSelectedItems(newSelected);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangePage(_: unknown, newPage: number) {
    setPage(newPage);
  }

  function submit() {
    setIsSaving(true);
    const siteIdsToSave = getItemsToSave(selectedItems);
    saveSites(authContextProps, searchParams.get('crmObjectId') ?? '', { almaIds: siteIdsToSave })
      .then(handleSubmitSuccess)
      .catch(handleSubmitError)
      .finally(() => {
        refresh();
        setIsSaving(false);
      });
  }

  function handleSubmitError(error: ApiError) {
    if (error.errorCode) {
      setSubmitErrorMessage('Toimipaikkojen tallentaminen ei onnistunut.');
      return;
    }
    setSubmitErrorMessage('Tallennettaessa tapahtui tuntematon virhe');
  }

  function handleSubmitSuccess(response: unknown) {
    setSubmitErrorMessage(undefined);
  }

  function cancel() {
    setSelectedItems(getSavedItems(selectedItems));
  }

  const buttonsDisabled = getUnsavedItems(selectedItems).length === 0 || isLoading || isSaving;

  const sites: Site[] = filterSites(data.sites, searchTerm).slice(0).sort(sortBySiteTypeCode);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography variant="body1" sx={{ padding: '8px 0px' }} color="text.secondary">
            Valitse toimipaikat, jotka tuodaan Hubspotiin. Jo lisätyt toimipaikat näkyvät listalla harmaana.
          </Typography>
          <TextField
            variant="outlined"
            sx={{ padding: '16px 16px' }}
            placeholder="Hae toimipaikkaa..."
            value={searchTerm}
            onChange={onSearchChange}
          />
          {submitErrorMessage && (
            <Typography variant="body1" sx={{ padding: '8px 0px' }} color="error">
              {submitErrorMessage}
            </Typography>
          )}
          <SiteImportTable
            rows={sites}
            numSelected={selectedItems.length}
            selectedItems={selectedItems}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onSelectItem={onSelectItem}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      {sites.length > 0 && (
        <ImportButtons buttonsDisabled={buttonsDisabled} submit={submit} cancel={cancel} loading={isSaving} />
      )}
    </Box>
  );
}
