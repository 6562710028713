import { useCodes } from 'api/code';
import { createContext, ReactNode, useContext } from 'react';
import { Code, compareCode, compareCodeExplanation } from 'types/code';
import { AuthContext } from 'context/AuthContext';

interface CodeContextInterface {
  readonly industries?: Code[];
  readonly roles?: Code[];
  readonly positionInGroup?: Code[];
  readonly isLoading: boolean;
}

export const CodeContext = createContext<CodeContextInterface | undefined>(undefined);

interface Props {
  readonly children: ReactNode;
}

const startNumericRegex = /^\d/;

export function CodeContextProvider(props: Props) {
  const authContextProps = useContext(AuthContext);
  const { data, isLoading } = useCodes(authContextProps);
  const industries =
    data
      ?.filter(({ codeSet }) => codeSet === 'CD')
      .filter(({ code }) => startNumericRegex.test(code))
      .sort(compareCode) ?? [];
  const roles = data?.filter(({ codeSet }) => codeSet === 'PR').sort(compareCodeExplanation);
  const positionInGroup = data?.filter(({ codeSet }) => codeSet === 'CG').sort(compareCodeExplanation);

  return (
    <CodeContext.Provider
      value={{
        industries,
        roles,
        positionInGroup,
        isLoading,
      }}
    >
      {props.children}
    </CodeContext.Provider>
  );
}
