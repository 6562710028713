import { createContext, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface AuthContextProperties {
  readonly signature: string;
  readonly timestamp: string;
  readonly app: string;
  readonly appId: string;
  readonly userId: string;
}

interface Props {
  readonly children: ReactNode;
}

export const AuthContext = createContext<AuthContextProperties>({
  signature: '',
  timestamp: '',
  app: '',
  appId: '',
  userId: '',
});

export function AuthContextProvider({ children }: Props) {
  const [searchParams] = useSearchParams();
  return (
    <AuthContext.Provider
      value={{
        signature: searchParams.get('sig') ?? '',
        timestamp: searchParams.get('timestamp') ?? '',
        app: searchParams.get('app') ?? '',
        appId: searchParams.get('appId') ?? '',
        userId: searchParams.get('userId') ?? '',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
