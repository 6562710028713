const startNumericRegex = /^\d/;

export interface Code {
  readonly code: string;
  readonly codeExplanationFi: string;
  readonly codeSet?: string;
  readonly codeSetFi?: string;
}

export interface HierarchicalCode {
  readonly code: Code;
  readonly children?: HierarchicalCode[];
}

export function industryCodesToHierarchical(codes: Code[]): HierarchicalCode[] {
  const numericCodes = codes
    .filter(({ code }) => startNumericRegex.test(code))
    .sort((a, b) => a.code.length - b.code.length);
  // Top level codes
  const result: HierarchicalCode[] = numericCodes
    .filter(({ code }) => code.length === 2)
    .map((code) => {
      return {
        code: code,
      };
    });

  // Second level codes
  numericCodes
    .filter(({ code }) => code.length === 3)
    .forEach((code) => {
      const parent = result.find((hCode) => hCode.code.code === code.code);
      parent?.children?.push({ code: code });
    });

  // Third level codes
  numericCodes
    .filter(({ code }) => code.length === 4)
    .forEach((code) => {
      const topParent = result.find((hCode) => hCode.code.code === code.code);
      const parent = topParent?.children?.find((hCode) => hCode.code.code === code.code);
      parent?.children?.push({ code: code });
    });

  // Fourth level codes
  numericCodes
    .filter(({ code }) => code.length === 5)
    .forEach((code) => {
      const topParent = result.find((hCode) => hCode.code.code === code.code);
      const midParent = topParent?.children?.find((hCode) => hCode.code.code === code.code);
      const parent = midParent?.children?.find((hCode) => hCode.code.code === code.code);
      parent?.children?.push({ code: code });
    });

  return result;
}

export function compareCodeExplanation(a: Code, b: Code): number {
  if (a.codeExplanationFi > b.codeExplanationFi) {
    return 1;
  }
  if (a.codeExplanationFi < b.codeExplanationFi) {
    return -1;
  }
  return 0;
}

export function compareCode(a: Code, b: Code): number {
  if (a.code > b.code) {
    return 1;
  }
  if (a.code < b.code) {
    return -1;
  }
  return 0;
}

export function compareCodeAlphabeticalFirst(a: Code, b: Code): number {
  const direction = startNumericRegex.test(a.code) !== startNumericRegex.test(b.code) ? -1 : 1;
  if (a.code > b.code) {
    return direction;
  }
  if (a.code < b.code) {
    return -direction;
  }
  return 0;
}
