import { Paper } from '@mui/material';
import { Contact } from 'api/types';
import { PluginTable } from 'components/PluginTable';
import { closeIsRowSelectedByAlmaId, getRowId, isRowDisabled } from 'components/common';
import { PluginTableColumn } from 'components/types';
import React from 'react';

interface Props {
  readonly numSelected: number;
  readonly rows: Contact[];
  readonly selectedItems: Contact[];
  readonly page: number;
  readonly rowsPerPage: number;
  readonly handleChangePage: (event: unknown, pageNumber: number) => void;
  readonly handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onSelectItem: (event: React.ChangeEvent, row: Contact) => void;
}

export default function ContactImportTable({
  rows,
  page,
  selectedItems,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onSelectItem,
}: Props) {
  const columns: PluginTableColumn[] = [
    { columnName: 'Nimi', objectKey: 'name' },
    { columnName: 'Sähköposti', objectKey: 'email' },
    { columnName: 'Titteli', objectKey: 'title' },
    { columnName: 'Roolit', objectKey: 'rolesDisplay' },
  ];
  const isRowSelected = closeIsRowSelectedByAlmaId(selectedItems);
  return (
    <Paper>
      <PluginTable<Contact>
        columns={columns}
        rows={rows}
        selectedItems={selectedItems}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onSelectItem={onSelectItem}
        isRowSelected={isRowSelected}
        isRowDisabled={isRowDisabled}
        getRowId={getRowId}
      />
    </Paper>
  );
}
