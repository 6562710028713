import { ApiResponse } from 'api/types';
import { jsonFetcher } from 'api/util';
import appConfig from 'appConfig';
import { AuthContextProperties } from 'context/AuthContext';
import { useState, useEffect } from 'react';
import { Code } from 'types/code';

export function useCodes({ signature, timestamp, app, appId, userId }: AuthContextProperties): ApiResponse<Code[]> {
  const [data, setData] = useState<Code[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(
    function fetchCodes() {
      const encodedSignature = encodeURIComponent(signature);
      const url = `${appConfig.backendUrl}/common/codes?sig=${encodedSignature}&timestamp=${timestamp}&app=${app}&appId=${appId}&userId=${userId}`;
      jsonFetcher<Code[]>(url)
        .then((codes) => {
          setData(codes);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    },
    [signature, timestamp, app, appId, userId],
  );
  return { data, isLoading };
}
